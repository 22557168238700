import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_DASHBOARD, TRAINER_ADEPTS, TRAINER_ADEPTS_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/trainer/userHomeworks/Editor';
import { TAB_USER_HOMEWORKS } from 'Components/pages/trainer/UsersManage/component';

export const TAB_DATA = 'data';

export default class TrainerUserHomeworkManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        userHomework: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { userHomework, location, history } = this.props;

        return (
            <StyledComponent
                className="trainer-user-homework-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={userHomework}
                >
                    {userHomework && userHomework.data && (
                        <PageContent
                            elementMode={true}
                            data={userHomework}
                            breadcrumbs={[{
                                to: withVariables(TRAINER_DASHBOARD.path),
                                label: 'Dashboard',
                            }, {
                                to: withVariables(TRAINER_ADEPTS.path),
                                label: 'Adepci',
                            }, {
                                to: withVariables(TRAINER_ADEPTS_MANAGE.path, { id: userHomework?.data?.user?.id }, { tab: TAB_USER_HOMEWORKS }),
                                label: 'Prace domowe',
                            }]}
                        >
                            <TabNavigation
                                location={location}
                                history={history}
                                headline="Szczegóły pracy domowej"                       
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            data={userHomework.data}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>
                    )}
                </Page>
            </StyledComponent>
        );
    }
}

